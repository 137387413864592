import { configureStore } from "@reduxjs/toolkit";
import authSliceService from "./auth/auth-slice.service";
import bookingSliceService from "./booking/booking-slice.service";
import cartSliceService from "./cart/cart-slice.service";
import creditSliceService from "./credits/credit-slice.service";
import experienceSliceService from "./experiences/experience-slice.service";
import favouriteSliceService from "./favourite/favourites-slice.service";
import guestSliceService from "./guest/guest-slice.service";
import orderSliceService from "./order/order-slice.service";
import pointsSliceService from "./point/points-slice.service";
import profileSliceService from "./profile/profile-slice.service";
import voucherSliceService from "./voucher/voucher-slice.service";
import marketingSliceService from "./marketing/marketing-slice.service";
import newsletterSliceService from "./marketing/newsletter/newsletter-slice.service";
import giftOrderSliceService from "./gift-order/gift-order-slice.service";

const store = configureStore({
  reducer: {
    auth: authSliceService,
    profile: profileSliceService,
    experience: experienceSliceService,
    favourite: favouriteSliceService,
    cart: cartSliceService,
    order: orderSliceService,
    giftOrder: giftOrderSliceService,
    booking: bookingSliceService,
    credits: creditSliceService,
    points: pointsSliceService,
    voucher: voucherSliceService,
    guest: guestSliceService,
    marketing: marketingSliceService,
    newsletter: newsletterSliceService,
  },
});

export default store;
