import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";

import PrivateRoute from "./private.route";
import PublicRoute from "./public.route";
import RestrictedRoute from "./restricted.route";

import AuthScreen from "../../features/auth/screens/auth-screen";
import ForgotPassword from "../../features/auth/screens/forgot-password.screen";
import VerificationScreen from "../../features/auth/screens/verification-code.screen";
import BookingExperiencesDetails from "../../features/bookings/screen/booking-experiences-details.screen";
import BookingExperiences from "../../features/bookings/screen/booking-experiences.screen";
import PendingConfirmation from "../../features/bookings/screen/booking-waiting-confirm.screen";
import DateTimeSelectScreen from "../../features/bookings/screen/date-time-selection.screen";
import MyCart from "../../features/cart/screen/my-cart.screen";
import AccountCreditsScreen from "../../features/credits/screen/account-credits.screen";
import TopUpBillPaymentCheckScreen from "../../features/credits/screen/top-up-bill-payment-check.screen";
import TopUpBillPaymentSuccessful from "../../features/credits/screen/top-up-bill-payment-success.screen";
import HomeScreen from "../../features/dashboard/screen/home.screen";
import ExperiencesDetails from "../../features/experiences-details/screen/experiences-details.screen";
import BestSellerScreen from "../../features/experiences-listing/screen/best-seller.screen";
import ExperiencesListingScreen from "../../features/experiences-listing/screen/experiences-listing.screen";
import ExploreScreen from "../../features/experiences-listing/screen/explore.screen";
import MyFavourites from "../../features/favourites/screen/my-favourites.screen";
import GiftCardOrderDetailScreen from "../../features/gift-card-orders/screen/gift-card-order-details.screen";
import GiftCardOrderScreen from "../../features/gift-card-orders/screen/gift-card-orders.screen";
import NewsletterSignUpScreen from "../../features/landing/screen/newsletter-sign-up.screen";
import MyOrderDetailScreen from "../../features/my-orders/screen/my-order-details.screen";
import MyOrderScreen from "../../features/my-orders/screen/my-orders.screen";
import CompleteProfileScreen from "../../features/profile/screen/complete-profile.screen";
import UserProfileMenu from "../../features/profile/screen/user-profile-menu.screen";
import UserProfile from "../../features/profile/screen/user-profile.screen";
import BillPaymentFailed from "../../features/purchasing/screen/bill-failed.screen";
import BillPaymentCheckScreen from "../../features/purchasing/screen/bill-payment-check.screen";
import BillPaymentSuccessful from "../../features/purchasing/screen/bill-successful.screen";
import BuyNowSecureCheckoutScreen from "../../features/purchasing/screen/buy-now-secure-checkout-screen";
import OrderDetailsScreen from "../../features/purchasing/screen/order-details.screen";
import PendingPaymentScreen from "../../features/purchasing/screen/pending-payment.screen";
import SecureCheckoutScreen from "../../features/purchasing/screen/secure-checkout.screen";
import GiftVoucherPaymentCheckScreen from "../../features/voucher/screens/gift-voucher-payment-check.screen";
import GiftVoucherPaymentSuccessScreen from "../../features/voucher/screens/gift-voucher-payment-success.screen";
import GiftVoucherPreviewScreen from "../../features/voucher/screens/gift-voucher-preview.screen";
import GiftVoucherRedeemedScreen from "../../features/voucher/screens/gift-voucher-redeemed.screen";
import GiftVoucherScreen from "../../features/voucher/screens/gift-voucher.screen";
import VoucherExperienceDetailScreen from "../../features/voucher/screens/voucher-experience-detail.screen";
import VoucherRedemptionScreen from "../../features/voucher/screens/voucher-redeem.screen";
import VoucherRedeemedScreen from "../../features/voucher/screens/voucher-redeemed.screen";
import CustomizedAppBar from "../../infrastructure/layout/appbar.layout";

function index() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("920"));
  return (
    <BrowserRouter>
      <Switch>
        <RestrictedRoute exact path="/auth" component={() => <AuthScreen />} />
        <RestrictedRoute exact path="/forgot_password" component={() => <ForgotPassword />} />
        <PrivateRoute
          exact
          path="/verification_code"
          component={(props) => <VerificationScreen {...props} />}
        />
        <PrivateRoute exact path="/complete_profile" component={() => <CompleteProfileScreen />} />
        <PublicRoute exact path="/newsletter" component={() => <NewsletterSignUpScreen />} />
        <CustomizedAppBar>
          <PublicRoute exact path="/" component={() => <HomeScreen />} />
          <PublicRoute
            exact
            path="/experiences_listing"
            component={(props) => <ExperiencesListingScreen {...props} />}
          />
          <PublicRoute exact path="/experiences/details" component={() => <ExperiencesDetails />} />
          <PrivateRoute exact path="/user_profile" component={() => <UserProfile />} />
          <PrivateRoute
            exact
            path="/user_profile_menu"
            component={() => (isMobile ? <UserProfileMenu /> : <UserProfile />)}
          />
          <PublicRoute exact path="/my_favourites" component={() => <MyFavourites />} />
          <PublicRoute exact path="/my_cart" component={() => <MyCart />} />
          <PrivateRoute exact path="/secure_checkout" component={() => <SecureCheckoutScreen />} />
          <PrivateRoute
            exact
            path="/bill_check"
            component={(props) => <BillPaymentCheckScreen {...props} />}
          />
          <PrivateRoute exact path="/bill_successful" component={() => <BillPaymentSuccessful />} />
          <PrivateRoute exact path="/bill_failed" component={() => <BillPaymentFailed />} />
          <PrivateRoute exact path="/pending_payment" component={() => <PendingPaymentScreen />} />
          <PrivateRoute exact path="/order_details" component={() => <OrderDetailsScreen />} />

          <PrivateRoute
            exact
            path="/booking_experiences"
            component={() => <BookingExperiences />}
          />
          <PrivateRoute
            exact
            path="/booking_experiences/details/orderId=:orderId&experienceId=:experienceId"
            component={(props) => <BookingExperiencesDetails {...props} />}
          />
          <PrivateRoute
            exact
            path="/select_datetime/:id"
            component={(props) => <DateTimeSelectScreen {...props} />}
          />
          <PrivateRoute
            exact
            path="/pending_confirmation"
            component={(props) => <PendingConfirmation {...props} />}
          />

          <PrivateRoute exact path="/account_credits" component={() => <AccountCreditsScreen />} />
          <PrivateRoute
            exact
            path="/top_up_bill_check"
            component={() => <TopUpBillPaymentCheckScreen />}
          />
          <PrivateRoute
            exact
            path="/top_up_bill_success/:billId"
            component={(props) => <TopUpBillPaymentSuccessful {...props} />}
          />

          <PrivateRoute exact path="/my_orders" component={() => <MyOrderScreen />} />
          <PrivateRoute exact path="/my_orders/details" component={() => <MyOrderDetailScreen />} />

          <PrivateRoute exact path="/gift_card_orders" component={() => <GiftCardOrderScreen />} />
          <PrivateRoute
            exact
            path="/gift_card_orders/details"
            component={() => <GiftCardOrderDetailScreen />}
          />

          <PrivateRoute
            exact
            path="/buy_now/secure_checkout/"
            component={(props) => <BuyNowSecureCheckoutScreen {...props} />}
          />

          <PublicRoute
            exact
            path="/voucher/redemption"
            component={(props) => <VoucherRedemptionScreen {...props} />}
          />
          <PublicRoute
            exact
            path="/voucher/details"
            component={(props) => <VoucherExperienceDetailScreen {...props} />}
          />
          <PublicRoute
            exact
            path="/voucher/redeemed"
            component={(props) => <VoucherRedeemedScreen {...props} />}
          />
          <PublicRoute exact path="/gift_voucher" component={() => <GiftVoucherScreen />} />
          <PublicRoute
            exact
            path="/gift_order_bill_check"
            component={() => <GiftVoucherPaymentCheckScreen />}
          />
          <PublicRoute
            exact
            path="/gift_order_bill_success"
            component={() => <GiftVoucherPaymentSuccessScreen />}
          />
          <PrivateRoute
            exact
            path="/gift_voucher/redeemed"
            component={() => <GiftVoucherRedeemedScreen />}
          />
          <PublicRoute
            exact
            path="/experiences/best_seller"
            component={() => <BestSellerScreen />}
          />
          <PublicRoute
            exact
            path="/gift_voucher/preview"
            component={(props) => <GiftVoucherPreviewScreen {...props} />}
          />
          <PublicRoute exact path="/experiences/explore" component={() => <ExploreScreen />} />
        </CustomizedAppBar>
      </Switch>
    </BrowserRouter>
  );
}

export default index;
